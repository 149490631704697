import $ from 'jquery';
if ( $( '.accordion .title' ).length ) {
  $( function() {
    $( '.accordion .title' ).on( 'click', function( e ) {
      e.preventDefault();
      
      var $this = $( this );
      var $accordion = $this.parent();
      var $content = $this.next();
  
      if( $accordion.prev().length ) {
        setTimeout( () => {
          $( 'html, body' ).animate( {
            scrollTop: $accordion.prev().position().top-150
          }, 750 )
        }, 250 )
        
      } else {
        $( 'html, body' ).animate( {
          scrollTop: $accordion.position().top-150
        }, 750 )
      }
  
      $accordion.parent().find( '.text-content' ).css( 'max-height', '0' );
  
      if ( $accordion.hasClass( 'open' ) ){
        $accordion.removeClass( 'open' );
        
      } else {
        $accordion.siblings().removeClass( 'open' );
        $accordion.addClass( 'open' );
        $content.css( 'max-height', $content[0].scrollHeight );
      }
    });
  
    // Go through each accordion
    $( '.accordion' ).each( function() {
      var $accordionClick = $( this ).find( '.title' )
      var $currentAccordion = $( this ).find( '.accordion-title' )
      var $prevAccordion = $( this ).prev().find( '.accordion-title' )
      var $nextAccordion = $( this ).next().find( '.accordion-title' )
      var $accordions = $( '.accordion' ).find( '.accordion-title' )
  
      $accordionClick.on( 'click', function() {
  
        // Reset all accordions if the active accordion is closed by user
        if( $currentAccordion.hasClass( 'active-heading' ) ) {
          $accordions.removeClass( 'active-heading-other active-heading-sibling active-heading' )
        } else {
          // If current accordion is closed by clicking on another accordion by user
          $accordions.removeClass( 'active-heading-other active-heading-sibling active-heading' )
          $accordions.addClass( 'active-heading-other' )
          $prevAccordion.addClass( 'active-heading-sibling' )
          $nextAccordion.addClass( 'active-heading-sibling' )
          $currentAccordion.addClass( 'active-heading' )
        }
      } )
    } )
  } )
}
